<template>
  <div style="overflow: hidden; height: 100vh">
    <img src="../assets/404.png" alt="" style="width: 100%; height: 100%" @click="logout">
  </div>
</template>

<script>
export default {
  name: "NotFound",

  methods: {
    logout() {
      this.$store.commit("logout")
      this.$message.success("退出成功")
    }
  }
}
</script>

<style>
.bgImg {
  background: url("../assets/404.png") no-repeat;
  background-size: 100% 100vh;
}
</style>
